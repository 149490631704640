.bodytext {
  p {
    margin-bottom: $grid-gutter-width * 0.75;
    font-size: px-to-rem(16px);
    line-height: 1.6;

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(20px);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:has(+ h2),
    &:has(+ h3) {
      margin-bottom: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(xl) {
        margin-bottom: $grid-gutter-width * 0.75;
      }
    }
  }

  // TODO: might not be the best solution, some1 maybe can refactor dis
  p ~ p:has(.btn-phone) {
    margin-top: -$grid-gutter-width * 0.75;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
