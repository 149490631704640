#header .logo {
  height: 16px;
  width: auto;

  @include media-breakpoint-up(md) {
    height: 22px;
  }

  @include media-breakpoint-up(xl) {
    height: 26px;
  }

  @include media-breakpoint-up(ultrawide) {
    height: 30px;
  }

  svg {
    height: 100%;
    width: auto;
  }
}

#footer .logo {
  color: __get($theme-colors, 'black');
  display: flex;
  width: 171px;
  height: 35px;

  @include media-breakpoint-up(md) {
    width: 300px;
  }

  @include media-breakpoint-up(lg) {
    width: 262px;
  }

  @include media-breakpoint-up(ultrawide) {
    width: 300px;
  }
}

.logo-vienna {
  width: 98px;
  height: auto;

  @include media-breakpoint-up(md) {
    width: 142px;
    height: auto;
  }
}
