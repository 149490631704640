@use 'sass:math';

.search {
  &-form {
    &:not(.results-hero &) {
      @include media-breakpoint-up(lg) {
        width: 85%;
      }

      @include media-breakpoint-up(xl) {
        width: 65%;
      }
    }

    &:focus-within:not(.search-form__mobile-list) {
      @include focus-ring-style;
      outline-offset: -2px;
      &:has(.btn:focus-visible) {
        outline: none;
      }
    }

    &__mobile-list {
      position: relative;
      .form-control:focus {
        @include focus-ring-style;
        outline-offset: -2px;
      }
    }

    .form-control {
      font-size: px-to-rem(16px);
      color: __get($theme-colors, 'dark');

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      &::placeholder {
        color: __get($theme-colors, 'dark');
      }

      &:focus {
        box-shadow: none;
      }
    }

    &__submit {
      &-wrapper {
        position: absolute;
        bottom: 8px;

        & + div {
          inset: px-to-rem(68px) 0 px-to-rem(89px) 0;
          position: absolute;
          overflow-y: auto;
        }
      }

      // suggest wrap
      & + div {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        right: 0;
      }
    }
  }
}
