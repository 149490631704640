@mixin font-styles($size, $style: 'normal') {
  font-size: px-to-rem($size);
  font-weight: normal;
  font-style: normal;

  @if ($style == 'normal') {
    font-family: __get($font-weights, 'normal.family');
  }

  @if ($style == 'medium') {
    font-family: __get($font-weights, 'medium.family');
  }

  @if ($style == 'bold') {
    font-family: __get($font-weights, 'bold.family');
  }

  @if ($style == 'italic') {
    font-family: $font-family-italic;
  }
}
