.results {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  row-gap: $grid-gutter-width * 1.75;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: $grid-gutter-width * 1.25;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: $grid-gutter-width * 1.5;
  }

  @include media-breakpoint-up(xxl) {
    column-gap: $grid-gutter-width * 2;
  }

  &-item {
    &:hover {
      .results-item__image picture img {
        transform: scale(1);
      }
    }

    &__image {
      display: grid;
      place-content: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;

      picture {
        overflow: hidden;

        img {
          transition: transform 0.33s ease-out;
          transform: scale(1.1);
        }
      }
    }

    &__logo {
      display: inline-block;
      max-width: 80px;

      @include media-breakpoint-up(xl) {
        max-width: 100px;
      }

      @include media-breakpoint-up(ultrawide) {
        max-width: 110px;
      }
    }

    &__heading {
      hyphens: auto;
    }

    &__categories {
      font-size: px-to-rem(16px);
      line-height: 1.6;

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(20px);
      }

      .separator:after {
        right: $grid-gutter-width * -0.25;
      }
    }

    .btn-more {
      padding-top: px-to-rem(12px);
      padding-bottom: px-to-rem(12px);
    }
  }

  &-hero {
    &__top {
      padding-right: calc(0.5 * var(--bs-gutter-x) - 8px);
      padding-left: calc(0.5 * var(--bs-gutter-x) - 8px);
    }

    &__results-count p {
      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(20px);
      }
    }

    &__facets-label {
      font-size: 18px;
      font-family: $headings-font-family;

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(22px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(24px);
      }
    }
  }

  &-pagination {
    .page {
      &-item.active {
        height: px-to-rem(35px);
      }

      &-link {
        &:not(.active) {
          padding: px-to-rem(6px) px-to-rem(12px);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(18px);
        }
      }
    }

    &__select {
      padding: px-to-rem(12px) $grid-gutter-width * 1.5 px-to-rem(12px) $grid-gutter-width * 0.5;
      background-image: $select-arrow;
      background-size: 12px 8px;
      background-position: right $grid-gutter-width * 0.5 center;
    }
  }
}
