@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

:root {
  --line-clamp: 2;
}

$line-clamp: (
  'line-clamp': (
    'on': (
      display: -webkit-box,
      -webkit-box-orient: vertical,
      -webkit-line-clamp: var(--line-clamp),
      overflow: hidden,
    ),
    'off': (
      display: initial,
    ),
  ),
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $key, $utility in $line-clamp {
      .#{$key}-#{$breakpoint} {
        @each $k, $v in $utility {
          &-#{$k} {
            @each $css, $value in $v {
              #{$css}: $value !important;
            }
          }
        }
      }
    }
  }
}
