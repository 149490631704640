.partners-list {
  @include media-breakpoint-up(xl) {
    font-size: px-to-rem(18px);
  }

  @include media-breakpoint-up(ultrawide) {
    font-size: px-to-rem(20px);
  }

  .accordion {
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: #{$body-color};
    --bs-accordion-border-color: transparent;

    --bs-accordion-btn-padding-x: #{$grid-gutter-width * 0.5};
    --bs-accordion-btn-padding-y: #{$grid-gutter-width * 0.5};

    --bs-accordion-body-padding-x: var(--bs-accordion-btn-padding-x);
    --bs-accordion-body-padding-y: var(--bs-accordion-btn-padding-y);

    --accordion-logo-width: 100px;
    --accordion-font-size: #{px-to-rem(16px)};

    @include media-breakpoint-up(xl) {
      --bs-accordion-btn-padding-x: #{$grid-gutter-width};
      --bs-accordion-btn-padding-y: #{$grid-gutter-width * 0.75};

      --accordion-logo-width: 124px;
      --accordion-font-size: #{px-to-rem(18px)};
    }

    @include media-breakpoint-up(ultrawide) {
      --accordion-logo-width: 132px;
      --accordion-font-size: #{px-to-rem(20px)};
    }

    &-header {
      &__title {
        font-family: $headings-font-family;
        font-size: px-to-rem(20px);
        grid-row: 2/2;
        grid-column: 1 / span 2;

        @include media-breakpoint-up(md) {
          grid-row: 1/1;
          grid-column: 2/2;
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(24px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(28px);
        }

        small {
          font-size: var(--accordion-font-size);
        }
      }

      &__image {
        grid-row: 1/1;
        grid-column: 1 / span 2;

        @include media-breakpoint-up(md) {
          grid-column: 1/1;
        }

        width: var(--accordion-logo-width);
        flex: 0 0 var(--accordion-logo-width);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__caret {
        grid-row: 1 / span 2;
        grid-column: 3;

        @include media-breakpoint-up(md) {
          grid-row: 1/1;
        }
      }
    }

    &-button {
      display: grid;
      grid-auto-rows: auto auto;
      grid-auto-columns: auto auto max-content;

      @include media-breakpoint-up(md) {
        grid-auto-columns: max-content auto max-content;
      }

      &:after {
        display: none;
      }
    }

    &-body {
      p {
        font-size: var(--accordion-font-size);
      }

      &:before {
        content: '';
        height: 1px;
        background: map-get($theme-colors, 'light-2');
        position: absolute;
        top: 0;
        left: var(--bs-accordion-btn-padding-x);
        right: var(--bs-accordion-btn-padding-x);
      }
    }
  }
}
