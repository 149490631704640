.tx-supi__simpleMaps {
  &-wrapper {
    &:has(.tx-supi__simpleMaps) {
      display: grid;
      place-content: center;

      .tx-supi__simpleMaps + a {
        display: none;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
