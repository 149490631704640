.frame-type-theme_textimage {
  figure.right {
    order: -1;

    @include media-breakpoint-up(md) {
      order: 1;
    }
  }

  p {
    font-size: px-to-rem(16px);

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(20px);
    }
  }
}
