@use 'sass:math';

// load all variables
@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

// Colors
$gray-200: #f3f3f3;
$gray-300: #eeeeee;
$gray-500: #bcbcbc;
$gray-700: #545660;
$gray-900: #212529;

$blue-200: #f7fbfc;
$blue-400: #f2f6f7;

$brown-900: #9e5a07;

$primary-600: #e50000;
$primary-700: #bb0000;
$primary-800: #c50000;

$green-600: #58a145;

$black: #000;
$dark: $gray-900;
$white: #fff;
$blue: $blue-200;
$green: $green-600;
$brown: $brown-900;

$primary: $primary-600;
$secondary: $green;
$info: $blue;

$body-color: $black;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'light': $gray-200,
  'light-2': $gray-300,
  'light-3': $gray-500,
  'body-color': $body-color,
  'action-1': $blue,
  'action-2': $gray-700,
  'action-3': $brown-900,
  'action-4': $primary-700,
  'action-5': $blue-400,
  'white': $white,
  'info': $info,
  'dark': $dark,
);

// Spacers
$enable-negative-margins: true;
$grid-gutter-width: 2rem;

// sigh - it hurts so much!
$spacers: (
  0: 0,
  vsg: $grid-gutter-width * 0.125,
  sg: $grid-gutter-width * 0.25,
  hg: $grid-gutter-width * 0.5,
  qp: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1v: $grid-gutter-width * 1.25,
  1h: $grid-gutter-width * 1.5,
  1t: $grid-gutter-width * 1.75,
  dg: $grid-gutter-width * 2,
  21v: $grid-gutter-width * 2.125,
  2v: $grid-gutter-width * 2.25,
  2h: $grid-gutter-width * 2.5,
  2hq: $grid-gutter-width * 2.75,
  tg: $grid-gutter-width * 3,
  tgq: $grid-gutter-width * 3.25,
  3h: $grid-gutter-width * 3.5,
  3hq: $grid-gutter-width * 3.75,
  qg: $grid-gutter-width * 4,
  4h: $grid-gutter-width * 4.5,
  5h: $grid-gutter-width * 5.5,
  6g: $grid-gutter-width * 6,
);

// Icon sizes
$icon-sizes: (
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  26: 26px,
  30: 30px,
  32: 32px,
  96: 96px,
);

// fonts
$enable-rfs: false;
$h1-font-size: clamp(px-to-rem(28px), 2vw + 1.2rem, px-to-rem(56px));
$font-family-sans-serif: 'Suisse-Intl', sans-serif;
$font-family-medium: 'Suisse-Intl-M', sans-serif;
$font-family-bold: 'Suisse-Intl-B', sans-serif;
$font-family-italic: 'Suisse-Intl-I', sans-serif;

$headings-font-family: 'Suisse-Neue', serif;
$headings-font-weight: normal;

$font-weights: (
  'normal': (
    'family': $font-family-sans-serif,
    'weight': normal,
  ),
  'medium': (
    'family': $font-family-medium,
    'weight': normal,
  ),
  'bold': (
    'family': $font-family-bold,
    'weight': normal,
  ),
);

$small-font-size: var(--small-font-size);

// buttons
$border-radius: 0;
$btn-box-shadow: 0;
$btn-border-width: 2px;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $btn-border-radius;
$btn-focus-box-shadow: none;
$btn-hover-bg: $primary-800;
$btn-hover-border-color: $primary-800;
$link-decoration: none;

$phone-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' id='ico-phone'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.6556 14.5778L17.1667 12.6556C16.2667 12.2667 15.2 12.5333 14.5778 13.2889L13.2333 14.9222L13.1111 14.8556C11.0222 13.6556 9.31111 11.9333 8.16667 9.86667L8.1 9.74444L9.68889 8.4C10.4556 7.77778 10.7111 6.71111 10.3222 5.8L8.4 1.32222C8.04444 0.488889 7.23333 -0.0222225 6.36667 -0.0222225C6.2 -0.0222225 6.03333 -2.99886e-07 5.86667 0.033333L1.72222 1.02222C0.722222 1.25556 0 2.14444 0 3.14444C0.0444444 14.1 8.93333 23 19.8222 23C20.8667 23 21.7556 22.2889 21.9778 21.2778L22.9333 17.1222C23.1778 16.0778 22.6333 15.0111 21.6556 14.5889V14.5778ZM21.0111 16.6778L20.0556 20.8222C20.0333 20.9333 19.9333 21.0111 19.8222 21.0111C9.97778 21.0222 1.97778 13.0111 1.97778 3.17778C1.97778 3.06667 2.04444 2.96667 2.15556 2.94444L6.32222 1.98889C6.42222 1.96667 6.55556 2.02222 6.6 2.13333L8.52222 6.61111C8.56667 6.7 8.54444 6.82222 8.45555 6.88889L6.23333 8.71111C5.85556 9.02222 5.74444 9.54444 5.95556 9.98889C7.45556 13.0444 9.96667 15.5556 13.0222 17.0556C13.4222 17.2667 14 17.1444 14.3 16.7778L16.1556 14.5222L16.2667 14.4889C16.2667 14.4889 16.2889 14.4889 16.3111 14.4889H16.4333L20.8889 16.4111C20.9778 16.4444 21.0333 16.5444 21.0333 16.6333V16.7L21.0111 16.6778Z' fill='%23e50000'/%3E%3C/svg%3E%0A");
$phone-icon-hover: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg' id='ico-phone'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.6556 14.5778L17.1667 12.6556C16.2667 12.2667 15.2 12.5333 14.5778 13.2889L13.2333 14.9222L13.1111 14.8556C11.0222 13.6556 9.31111 11.9333 8.16667 9.86667L8.1 9.74444L9.68889 8.4C10.4556 7.77778 10.7111 6.71111 10.3222 5.8L8.4 1.32222C8.04444 0.488889 7.23333 -0.0222225 6.36667 -0.0222225C6.2 -0.0222225 6.03333 -2.99886e-07 5.86667 0.033333L1.72222 1.02222C0.722222 1.25556 0 2.14444 0 3.14444C0.0444444 14.1 8.93333 23 19.8222 23C20.8667 23 21.7556 22.2889 21.9778 21.2778L22.9333 17.1222C23.1778 16.0778 22.6333 15.0111 21.6556 14.5889V14.5778ZM21.0111 16.6778L20.0556 20.8222C20.0333 20.9333 19.9333 21.0111 19.8222 21.0111C9.97778 21.0222 1.97778 13.0111 1.97778 3.17778C1.97778 3.06667 2.04444 2.96667 2.15556 2.94444L6.32222 1.98889C6.42222 1.96667 6.55556 2.02222 6.6 2.13333L8.52222 6.61111C8.56667 6.7 8.54444 6.82222 8.45555 6.88889L6.23333 8.71111C5.85556 9.02222 5.74444 9.54444 5.95556 9.98889C7.45556 13.0444 9.96667 15.5556 13.0222 17.0556C13.4222 17.2667 14 17.1444 14.3 16.7778L16.1556 14.5222L16.2667 14.4889C16.2667 14.4889 16.2889 14.4889 16.3111 14.4889H16.4333L20.8889 16.4111C20.9778 16.4444 21.0333 16.5444 21.0333 16.6333V16.7L21.0111 16.6778Z' fill='%23c50000'/%3E%3C/svg%3E%0A");

// form
$input-padding-x: $grid-gutter-width * 0.75;
$input-padding-y: $grid-gutter-width * 0.5;
$input-btn-padding-x: $input-padding-x;
$input-btn-padding-y: $input-padding-y;
$input-placeholder-color: $body-color;
$form-label-color: $primary;
$form-label-font-size: 0.75rem;
$form-select-padding-x: $grid-gutter-width * 0.75;
$form-select-padding-y: $grid-gutter-width * 0.5;

// lists
$list-icon: url("data:image/svg+xml,%3Csvg width='13' height='19' viewBox='0 0 13 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.55928 9.52405L0.583326 2.5481L2.88142 0.25L12.1555 9.52405L2.88142 18.7981L0.583326 16.5L7.55928 9.52405Z' fill='%23EB0000'/%3E%3C/svg%3E%0A");

// marks
$mark-bg: transparent;
$mark-padding: 0;

// alert
$alert-padding-y: $grid-gutter-width * 0.75;
$alert-padding-x: $grid-gutter-width * 0.75;
$alert-margin-bottom: 0;
$alert-link-font-weight: normal;
$alert-bg-scale: 0%;
$alert-border-scale: 2%;
$alert-color-scale: 100%;

// badges
$badge-font-size: px-to-rem(14px);
$badge-font-weight: normal;
$badge-padding-x: px-to-rem(12px); // sigh!
$badge-padding-y: $grid-gutter-width * 0.25;

// form filter
$radio-checked-icon: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5571 6.70712L9.84999 18.4142L4.14288 12.7071L5.5571 11.2929L9.84999 15.5858L20.1429 5.29291L21.5571 6.70712Z' fill='%23212529'/%3E%3C/svg%3E%0A");
$filter-dropdown-shadow: 0px 3px 5px rgba(50, 50, 50, 0.2);

//pagination dropdown arrow
$select-arrow: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.55798 8.10309L12.616 1.67763L11.2697 0.249756L6.55798 5.24733L1.84622 0.249756L0.5 1.67763L6.55798 8.10309Z' fill='%23212529'/%3E%3C/svg%3E%0A");
