@use 'sass:math';

.frame-type-events_detail {
  .hero-background {
    background: url(../Images/illustration-clouds-with-objects-big.svg) no-repeat center bottom;
    background-size: calc(100% - var(--bs-gutter-x));
  }

  .hero-grid {
    --hero-area: 'image' 'content';
    --hero-rows: auto auto;

    @include media-breakpoint-up(md) {
      --hero-area: 'content image';
      --hero-columns: #{percentage(math.div(7, 12))} #{percentage(math.div(5, 12))};
    }

    @include media-breakpoint-up(lg) {
      --hero-columns: #{percentage(math.div(6, 12))} #{percentage(math.div(6, 12))};
    }

    @include media-breakpoint-up(ultrawide) {
      --hero-columns: #{percentage(math.div(7, 12))} #{percentage(math.div(5, 12))};
    }

    &__content {
      &-text {
        .badge {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      header h1 {
        font-size: clamp(px-to-rem(28px), 2vw + 0.7rem, px-to-rem(56px));
      }
    }

    &__image {
      grid-area: image;

      .figure-caption {
        bottom: 0;
        background: rgba(__get($theme-colors, 'action-1'), 0.9);
      }
    }
  }

  .event-detail {
    hr {
      --hr-width: calc(100% - var(--bs-gutter-x));
      --hr-margin: 0 auto;
    }

    &__teaser {
      font-size: px-to-rem(18px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(20px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(24px);
      }
    }

    &__bookingPeriod {
      font-size: px-to-rem(16px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(20px);
      }
    }

    &__partner-logo {
      --logo-height: 91px;
      --picture-width: 165px;

      @include media-breakpoint-up(md) {
        --logo-height: 70px;
      }

      @include media-breakpoint-up(xl) {
        --logo-height: 95px;
      }

      picture {
        display: block;
        width: var(--picture-width);
        height: var(--logo-height);
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: bottom left;
      }
    }

    &__infos {
      display: grid;
      grid-template-rows: 1fr auto 1fr;
      grid-template-columns: repeat(2, calc(50% - #{$grid-gutter-width * 0.25}));
      grid-template-areas:
        'col1 col2'
        'sep sep'
        'col3 col3';

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: 'col1 col2 col3';
      }

      & > hr {
        grid-area: sep;
      }

      & > div {
        @for $i from 1 through 3 {
          &:nth-of-type(#{$i}) {
            grid-area: col#{$i};
          }
        }

        &:nth-child(n + 2).separator {
          &:after {
            display: none;

            @include media-breakpoint-up(md) {
              display: block;
            }
          }
        }
      }

      &-header {
        font-size: px-to-rem(10px);
        font-family: $font-family-medium;
        letter-spacing: 0.05em;

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(12px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(14px);
        }
      }

      &-text {
        font-family: $headings-font-family;
        word-break: break-word;
        font-size: px-to-rem(20px);

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(22px);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(28px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(32px);
        }
      }

      .separator {
        &:after {
          right: $grid-gutter-width * -0.25;
          background: $gray-300;
        }
      }
    }

    &__period {
      @include media-breakpoint-up(xl) {
        --small-font-size: #{px-to-rem(18px)};
      }

      &-date {
        font-family: $font-family-medium;

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(20px);
        }
      }
    }

    &__partner {
      &-name {
        @include font-styles(16px, 'medium');

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(20px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(22px);
        }
      }
    }

    &__mail {
      a {
        word-break: break-all;
      }
    }

    &__name {
      margin-bottom: $grid-gutter-width * 0.25;
      font-size: px-to-rem(20px);
    }

    &__www {
      &-link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__appointments {
      .h4 {
        --small-font-size: #{px-to-rem(22px)};
        --bs-font-sans-serif: #{$headings-font-family};
        --small-color: #{$body-color};
      }
    }

    &__contact,
    &__address,
    &__publicTransportation,
    &__www {
      font-size: px-to-rem(16px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(20px);
      }
    }

    &__location {
      svg {
        margin-top: 4px;
      }
    }
  }
}
