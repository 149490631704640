.icon {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @each $icon, $size in $icon-sizes {
      @if $breakpoint == 'xs' {
        &-#{$icon} {
          width: $size;
          height: $size;
          flex: 0 0 $size;
        }
      } @else {
        &-#{$breakpoint}-#{$icon} {
          @include media-breakpoint-up($breakpoint) {
            width: $size;
            height: $size;
            flex: 0 0 $size;
          }
        }
      }
    }
  }

  &-caret {
    transition: $transition-base;

    &__down {
    }
    &__up {
    }
    &__left {
      transform: rotate(90deg);
    }
    &__right {
      transform: rotate(-90deg);
    }

    [aria-expanded='true'] & {
      transform: rotate(180deg);
    }
  }

  &-chevron {
    .results-pagination & {
      @include media-breakpoint-up(xl) {
        margin-top: -$grid-gutter-width * 0.125;
      }
    }
  }
}
