.btn {
  --bs-btn-padding-x: #{$grid-gutter-width};
  --bs-btn-padding-y: #{$grid-gutter-width * 0.5};

  text-transform: uppercase;
  font-size: px-to-rem(14px);
  letter-spacing: 0.05em;
  font-family: $font-family-medium;

  @include media-breakpoint-up(xl) {
    --bs-btn-padding-x: #{$grid-gutter-width * 1.25};
    --bs-btn-padding-y: #{px-to-rem(20px)};

    font-size: px-to-rem(18px);
  }

  &:focus-visible {
    --bs-btn-hover-bg: #{$primary-600};
    --bs-btn-hover-border-color: #{$primary-600};
  }

  &-primary {
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: #{$gray-500};
    --bs-btn-disabled-opacity: 1;
    --bs-btn-disabled-border-color: #{$gray-500};
  }

  &-outline-primary {
    --bs-btn-disabled-color: #{$gray-500};
    --bs-btn-disabled-bg: #{$white};
    --bs-btn-disabled-opacity: 1;
    --bs-btn-disabled-border-color: #{$gray-500};

    &:hover {
      --bs-btn-hover-bg: #{$primary-800};
      --bs-btn-hover-border-color: #{$primary-800};
    }
  }

  &-link {
    padding: 0;
    text-transform: uppercase;

    &:focus-visible {
      --bs-btn-hover-bg: #{$white};
      --bs-btn-hover-border-color: #{$white};
    }
  }

  &-phone {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: $grid-gutter-width * 0.25 0;

    &:before {
      content: $phone-icon;
      width: 22px;
      height: 22px;
      margin-right: $grid-gutter-width * 0.25;

      @include media-breakpoint-up(ultrawide) {
        width: 24px;
        height: 24px;
      }
    }

    &:hover:before {
      content: $phone-icon-hover;
    }
  }

  &-search {
    padding-top: px-to-rem(12px);
    padding-bottom: px-to-rem(12px);
  }

  &-reset {
    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(16px);
    }
  }
}
