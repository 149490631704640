@use 'sass:color';

.badge {
  --bs-badge-font-size: #{px-to-rem(12px)};
  --bs-badge-padding-x: #{px-to-rem(16px)};
  --bs-badge-padding-y: #{px-to-rem(10px)};
  --bs-badge-font-family: #{$font-family-medium};

  @include media-breakpoint-up(xl) {
    --bs-badge-font-size: #{px-to-rem(14px)};
    --bs-badge-padding-x: #{px-to-rem(16px)};
    --bs-badge-padding-y: #{px-to-rem(12px)};
  }

  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: $grid-gutter-width * 0.125;
  min-height: $grid-gutter-width;
  font-family: var(--bs-badge-font-family);

  figure & {
    position: absolute;
    bottom: $grid-gutter-width * 0.5;
    right: $grid-gutter-width * 0.5;
  }

  // badge variants
  @each $colorName, $colorCode in $theme-colors {
    &-#{$colorName} {
      color: $colorCode;
      background: color.scale($colorCode, $lightness: 90%);
    }
  }
}
