.bodytext {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width * 0.75;
    }

    @include media-breakpoint-up(ultrawide) {
      padding-left: $grid-gutter-width;
    }

    li {
      position: relative;
      padding-left: $grid-gutter-width * 1.25;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: $list-icon no-repeat center;
        width: px-to-rem(24px);
        height: px-to-rem(24px);
        background-size: 12px;

        @include media-breakpoint-up(xl) {
          width: px-to-rem(30px);
          height: px-to-rem(30px);
          background-size: 14px;
        }
      }
    }

    ul {
      flex: 1 0 100%;
      margin-top: $grid-gutter-width * 0.75;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(xl) {
      margin-left: $grid-gutter-width * 0.75;
    }

    @include media-breakpoint-up(ultrawide) {
      margin-left: $grid-gutter-width;
    }

    li {
      padding-left: $grid-gutter-width * 0.25;
    }

    ol {
      margin-top: $grid-gutter-width * 0.75;
    }
  }

  > ul,
  > ol {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    font-size: px-to-rem(16px);

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
      margin-top: $grid-gutter-width * 1.25;
      margin-bottom: $grid-gutter-width * 1.25;
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(20px);
    }
  }
}
