@each $i in (1, 2, 3, 4) {
  .h#{$i},
  h#{$i} {
    hyphens: auto;
    text-wrap: balance;
  }
}

h1,
.h1 {
  font-size: clamp(px-to-rem(28px), 2vw + 1.2rem, px-to-rem(56px));
  margin-bottom: $grid-gutter-width * 0.75;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width;
  }
}

h2,
.h2 {
  font-size: clamp(px-to-rem(24px), 2vw + 0.3rem, px-to-rem(38px));
  margin-bottom: $grid-gutter-width * 0.5;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width * 0.75;
  }
}

h3,
.h3 {
  font-size: clamp(px-to-rem(20px), 2vw, px-to-rem(30px));
  margin-bottom: $grid-gutter-width * 0.5;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    margin-bottom: $grid-gutter-width * 0.75;
  }
}

h4,
.h4 {
  font-size: clamp(px-to-rem(16px), 2vw, px-to-rem(24px));
  margin-bottom: $grid-gutter-width * 0.5;
  line-height: 1.4;
}
