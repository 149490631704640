@use 'sass:math';

.hero {
  --bs-link-color: #{$white};
  --bs-link-hover-color: #{$white};
  color: $white;

  .bg-white {
    color: __get($theme-colors, 'dark');
  }

  &-grid {
    --hero-area: 'content' 'image' 'button';
    --hero-rows: 1fr auto auto;
    --hero-columns: 1fr;

    display: grid;
    grid-template-areas: var(--hero-area);
    grid-template-rows: var(--hero-rows);
    grid-template-columns: var(--hero-columns);

    &__content {
      grid-area: content;
      position: relative;
      z-index: 2;

      &-text {
        @include media-breakpoint-up(md) {
          width: percentage(math.div(7, 12));
        }

        @include media-breakpoint-up(xl) {
          width: percentage(math.div(6, 12));
        }
      }

      &-link {
        font-size: px-to-rem(18px);
        text-decoration: none;
        letter-spacing: 1px;

        &:focus-visible {
          outline-color: __get($theme-colors, 'white') !important;
        }
      }

      .frame-type-theme_pageheader & h1 {
        @include media-breakpoint-up(xl) {
          margin-bottom: $grid-gutter-width * 0.75;
        }

        @include media-breakpoint-up(ultrawide) {
          margin-bottom: $grid-gutter-width;
        }
      }

      .frame-type-theme_pageheader & .bodytext p {
        font-size: 18px;

        @include media-breakpoint-up(xl) {
          font-size: 20px;
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: 24px;
        }
      }
    }

    &__image {
      grid-area: image;

      @include media-breakpoint-up(md) {
        grid-area: content;
      }

      margin: 0 -#{$grid-gutter-width * 0.5};

      @include media-breakpoint-up(md) {
        margin: 0 -#{$grid-gutter-width * 0.75};
      }

      @include media-breakpoint-up(xl) {
        margin: 0 calc(var(--bs-gutter-x) * -0.5);
      }

      &-paperplane {
        z-index: 1;
        width: 100%;
        height: auto;
        display: block;
        position: relative;

        @include media-breakpoint-up(md) {
          width: 70%;
          transform: translate(170px, -90px);
        }

        @include media-breakpoint-up(xl) {
          width: 60%;
          transform: translate(80px, -190px);
        }

        @include media-breakpoint-up(xl) {
          width: 70%;
          transform: translate(170px, -40px) scale(0.75);
        }

        @include media-breakpoint-up(ultrawide) {
          width: auto;
          transform: translate(103px, -60px) scale(0.8);
        }
      }

      &-clouds {
        z-index: 0;
        position: absolute;
        bottom: -160px;
        left: 50%;
        width: 900px;
        height: auto;
        margin-left: -450px;

        @include media-breakpoint-up(sm) {
          bottom: -140px;
        }

        @include media-breakpoint-up(md) {
          bottom: -180px;
          width: auto;
          left: 30%;
          margin: 0;
        }

        @include media-breakpoint-up(xl) {
          left: 35%;
        }

        @include media-breakpoint-up(ultrawide) {
          width: auto;
          left: auto;
          right: 0;
          bottom: -190px;
        }
      }
    }

    &__btn {
      grid-area: button;
      width: calc(100% + #{$grid-gutter-width});

      margin: 0 -#{$grid-gutter-width * 0.5};
    }
  }
}

@include motion-safe {
  #illustration-paperplane {
    &__person1 {
      animation: updown 1.5s 2s alternate infinite ease-in-out;
    }

    &__cloud1 {
      animation: leftright 5s alternate infinite ease-in-out;
    }

    &__cloud2 {
      animation: leftright 6s 2s alternate infinite ease-out;
    }

    &__cloud3 {
      transform: translateX(calc(var(--illu-px) * -1));
    }

    &__redCircle {
      transform: rotate(var(--illu-deg, 0deg));
      transform-origin: center right;
    }
  }
}

@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes leftright {
  0% {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(50px);
  }
}
