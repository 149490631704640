#header {
  .nav {
    &-burger {
      &__wrapper {
        top: var(--nav-height);
        left: auto;
        right: -800px;
        transition: right 500ms ease;
        height: calc(100vh - var(--nav-height));

        background: url(../Images/illustration-clouds-with-people.svg) no-repeat left bottom;
        background-size: contain;

        @include media-breakpoint-up(lg) {
          background: url(../Images/illustration-clouds-with-people.svg) no-repeat left bottom,
            url(../Images/illustration-clouds-with-objects.svg) no-repeat right top;
          background-size: contain, auto;
        }

        @include media-breakpoint-up(xxl) {
          background: url(../Images/illustration-clouds-with-people.svg) no-repeat left bottom,
            url(../Images/illustration-clouds-with-objects.svg) no-repeat right 72px top 36px;
          background-size: contain, auto;
        }

        &.collapsing {
          transition: height 0ms;
        }

        &.show {
          right: 0;
        }
      }

      &__list {
        li {
          display: inline-flex;
          width: fit-content;
          width: -moz-fit-content;

          a {
            font-family: $headings-font-family;
            span {
              transition: padding-left 250ms ease-in-out;
            }

            svg {
              opacity: 0;
              width: 0;
            }
          }
        }

        &-main li a {
          &:hover,
          &:focus-visible {
            text-decoration: none;

            @include media-breakpoint-up(xl) {
              span {
                padding-left: $grid-gutter-width;
              }

              svg {
                opacity: 1;
                width: 32px;
              }
            }
          }
        }

        &-sub li a {
          &:hover,
          &:focus-visible {
            text-decoration: none;

            @include media-breakpoint-up(xl) {
              span {
                padding-left: $grid-gutter-width * 0.5;
              }

              svg {
                opacity: 1;
                width: 24px;
              }
            }
          }
        }
      }

      &__list-main a {
        font-size: px-to-rem(22px);

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(26px);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(30px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(36px);
        }
      }

      &__list-sub a {
        font-size: px-to-rem(18px);

        @include media-breakpoint-up(md) {
          font-size: px-to-rem(22px);
        }

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(24px);
        }

        @include media-breakpoint-up(ultrawide) {
          font-size: px-to-rem(28px);
        }
      }
    }
  }
}

#top.nav-open {
  overflow-y: hidden;
  z-index: -1;

  #header #nav-burger {
    overflow-y: scroll;
    @include media-breakpoint-up(md) {
      &:after {
        z-index: -1;
        content: '';
        inset: var(--nav-height) 0 0 0;
        height: 100%;
        position: fixed;
        background-color: rgba($black, 0.2);
      }
    }
  }
}
