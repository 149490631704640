.filter-form-list {
  &:not(.filter-form-list__mobile) {
    .filter-form-list__inner {
      padding-right: calc(0.5 * var(--bs-gutter-x) - 8px);
      padding-left: calc(0.5 * var(--bs-gutter-x) - 8px);
    }
  }

  &__mobile-button-wrapper {
    position: absolute;
    bottom: $grid-gutter-width * 0.75;
    left: 0;
    right: 0;
  }

  &__submit {
    @include media-breakpoint-up(xl) {
      padding-top: px-to-rem(11.5px);
      padding-bottom: px-to-rem(11.5px);
    }
  }
}
