#faq {
  .accordion {
    &-button {
      font-size: px-to-rem(18px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(20px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(22px);
      }
    }

    &-body {
      font-size: px-to-rem(16px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      @include media-breakpoint-up(ultrawide) {
        font-size: px-to-rem(20px);
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}
