.frame-type-theme_teaser_text {
  p {
    font-size: clamp(px-to-rem(20px), 2vw, px-to-rem(30px));
    font-family: $headings-font-family;
    line-height: 30px;

    @include media-breakpoint-up(xl) {
      line-height: 39px;
    }

    @include media-breakpoint-up(ultrawide) {
      line-height: 45px;
    }
  }
}
