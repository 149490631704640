.separator {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: 0px;
    bottom: 0px;
    right: $grid-gutter-width * -0.5;
    background: $body-color;
  }

  &__small:after {
    top: 4px;
    bottom: 4px;
  }
}

*:not(.event-detail__infos):has(> .separator):last-of-type .separator:after {
  display: none;
}

.event-detail__infos .separator:last-of-type:after {
  display: none;
}

.results-item__categories .separator:after {
  top: auto;
  height: 14px;

  @include media-breakpoint-up(xl) {
    height: 16px;
  }

  @include media-breakpoint-up(ultrawide) {
    height: 18px;
  }
}
