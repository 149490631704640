.tx-solr-suggest-box {
  --bs-link-color: #{$body-color};
  --bs-link-hover-color: #{$body-color};

  &:not(.search-form__mobile-list &) {
    background: $white;
    box-shadow: 3px 3px 3px rgba($body-color, 0.1);
  }

  font-size: px-to-rem(16px);

  @include media-breakpoint-up(xl) {
    font-size: px-to-rem(18px);
  }

  ul {
    @extend .list-unstyled, .m-0, .pt-sg, .pb-sg, .pb-md-hg;

    li {
      display: flex;

      a {
        @extend .w-100;
        padding: px-to-rem(12px) $grid-gutter-width * 0.5;

        @include media-breakpoint-up(xl) {
          padding-left: $grid-gutter-width * 0.75;
          padding-right: $grid-gutter-width * 0.75;
        }

        .search-form__mobile-list & {
          padding: $grid-gutter-width * 0.25 0;
        }

        &.hover {
          background: __get($theme-colors, 'light');
        }
      }
    }

    + ul:before {
      @extend .mb-sg, .text-medium;

      padding-left: $grid-gutter-width * 0.5;
      padding-right: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(xl) {
        padding-left: $grid-gutter-width * 0.75;
        padding-right: $grid-gutter-width * 0.75;
      }

      .search-form__mobile-list & {
        padding: $grid-gutter-width * 0.25 0;
      }

      content: 'Angebote';
      display: block;
      font-size: 0.75rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: __get($theme-colors, 'primary');
    }
  }
}
