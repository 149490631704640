.frame-type-theme_textimage_background {
  @include media-breakpoint-up(ultrawide) {
    background: url(../Images/illustration-clouds-with-objects-big.svg) center bottom no-repeat;
    background-size: contain;
  }

  figure.right {
    order: -1;

    @include media-breakpoint-up(md) {
      order: 1;
    }
  }

  p {
    font-size: px-to-rem(16px);

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }

    @include media-breakpoint-up(ultrawide) {
      font-size: px-to-rem(20px);
    }
  }

  .content {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .figcaption-wrapper {
    bottom: 0;
    width: calc(100% - var(--gutter-x));
    background: rgba(__get($theme-colors, 'action-1'), 0.9);

    @include media-breakpoint-up(md) {
      width: calc(100% - (var(--gutter-x) * 0.5));
    }
  }
}
