.alert {
  font-family: $font-family-medium;
  font-size: px-to-rem(16px);

  @include media-breakpoint-up(xl) {
    font-size: px-to-rem(18px);
  }

  &-link {
    font-size: px-to-rem(14px);
    white-space: nowrap;
    letter-spacing: 0.05em;

    @include media-breakpoint-up(md) {
      align-self: flex-start;
    }

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(16px);
    }
  }

  svg {
    flex: 0 0 auto;
  }
}
