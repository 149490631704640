.select {
  &-wrapper {
    &:has(.select-items-wrapper.show),
    &:focus-within {
      @include focus-ring-style;
      outline-offset: -2px;
      .select-header:focus-visible {
        outline: none !important;
      }
    }

    .form-label {
      letter-spacing: 0.05em;
    }
  }

  &-header {
    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  &-items-wrapper {
    left: 0;
    box-shadow: $filter-dropdown-shadow;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: px-to-rem(24px);
      background: linear-gradient(180deg, rgba($white, 0), 50%, rgba($white, 1) 100%);
    }
  }

  &-items-inner {
    max-height: 350px;
    overflow-y: auto;
  }

  &-item {
    display: flex;

    &.hidden {
      display: none;
    }

    &:hover {
      background: __get($theme-colors, 'light');
    }

    .form-check-label {
      font-size: px-to-rem(16px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }

      &:hover {
        cursor: pointer;
      }

      &:has(.form-check-input:checked) {
        font-family: $font-family-medium;
      }

      &:has(:focus-visible) {
        @include focus-ring-style;
        outline-offset: -2px;
      }
    }

    .form-check-input {
      appearance: none;
      width: 24px;
      height: 24px;

      &:focus-visible {
        outline: none;
      }

      &:checked {
        background: $radio-checked-icon no-repeat;
      }
    }
  }
}
