.accordion {
  &-button {
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: #{$body-color};

    svg {
      transition: transform 0.2s ease-in-out;
      transform: rotate(0);
    }

    &[aria-expanded='true'] svg {
      transform: rotate(-180deg);
    }

    &:after {
      display: none;
    }
  }
}
