#footer .footer__inner {
  position: relative;
  background: url(../Images/clouds.svg) center bottom no-repeat;

  &:before,
  &:after {
    @include media-breakpoint-up(md) {
      content: '';
    }

    position: absolute;
    background-size: contain !important;
    z-index: 0;
  }

  &:before {
    background: url(../Images/illustration-objects.svg) no-repeat center;
    width: 190px;
    height: 120px;
    left: -90px;
    bottom: 110px;

    @include media-breakpoint-up(xl) {
      width: 236px;
      left: -60px;
    }
  }

  &:after {
    background: url(../Images/illustration-people.svg) no-repeat center;
    width: 337px;
    height: 298px;
    right: 0px;

    @include media-breakpoint-up(md) {
      bottom: 70px;
      width: 200px;
    }

    @include media-breakpoint-up(xl) {
      width: 337px;
    }

    @include media-breakpoint-up(ultrawide) {
      top: 0;
      bottom: auto;
    }
  }

  --bs-link-color: #{$body-color};
  --bs-link-hover-color: #{$body-color};
}
