:root {
  --gutter-x: #{$grid-gutter-width};
  --small-font-size: #{px-to-rem(14px)};
  --small-color: var(--bs-action-2-rgb);

  @include media-breakpoint-up(md) {
    --gutter-x: #{$grid-gutter-width * 1.5};
  }

  @include media-breakpoint-up(xl) {
    --gutter-x: #{$grid-gutter-width * 2};
  }

  @include media-breakpoint-up(ultrawide) {
    --gutter-x: #{$grid-gutter-width};
  }
}

html {
  scroll-padding-top: calc(var(--nav-height) + #{$grid-gutter-width * 0.5});
}
