@font-face {
  font-family: 'Suisse-Neue';
  src: url('../Webfonts/SuisseNeue-Medium-WebS.woff2') format('woff2'),
    url('../Webfonts/SuisseNeue-Medium-WebS.woff') format('woff'),
    url('../Webfonts/SuisseNeue-Medium-WebS.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Suisse-Intl';
  src: url('../Webfonts/SuisseIntl-Regular-WebS.woff2') format('woff2'),
    url('../Webfonts/SuisseIntl-Regular-WebS.woff') format('woff'),
    url('../Webfonts/SuisseIntl-Regular-WebS.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Suisse-Intl-M';
  src: url('../Webfonts/SuisseIntl-Medium-WebS.woff2') format('woff2'),
    url('../Webfonts/SuisseIntl-Medium-WebS.woff') format('woff'),
    url('../Webfonts/SuisseIntl-Medium-WebS.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Suisse-Intl-B';
  src: url('../Webfonts/SuisseIntl-Bold-WebS.woff2') format('woff2'),
    url('../Webfonts/SuisseIntl-Bold-WebS.woff') format('woff'),
    url('../Webfonts/SuisseIntl-Bold-WebS.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Suisse-Intl-I';
  src: url('../Webfonts/SuisseIntl-RegularItalic-WebS.woff2') format('woff2'),
    url('../Webfonts/SuisseIntl-RegularItalic-WebS.woff') format('woff'),
    url('../Webfonts/SuisseIntl-RegularItalic-WebS.ttf') format('truetype');
  font-display: block;
}
