.footer {
  a {
    text-decoration: none;
  }

  &-menu {
    flex-basis: auto;

    @include media-breakpoint-up(xl) {
      flex-basis: 50%;
    }

    @include media-breakpoint-up(ultrawide) {
      flex-basis: auto;
    }

    &__item {
      & > ul.collapse {
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }

    &__heading {
      font-family: $headings-font-family;
      font-size: px-to-rem(16px);

      @include media-breakpoint-up(md) {
        font-size: px-to-rem(18px);
        pointer-events: none;
      }

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(22px);
      }
    }

    &__link {
      font-size: px-to-rem(14px);

      @include media-breakpoint-up(md) {
        font-size: px-to-rem(16px);
      }

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(18px);
      }
    }
  }

  &-meta {
    a {
      font-size: px-to-rem(12px);

      @include media-breakpoint-up(md) {
        font-size: px-to-rem(14px);
      }

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(16px);
      }
    }

    &__copyright {
      font-size: px-to-rem(12px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(16px);
      }
    }
  }
}
